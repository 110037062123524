@import "../../styles/const";

.form {
  height: 100%;
  display: flex;

  &_field {
    margin-bottom: calcVH(40);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_input {
    width: 100%;
    font-size: calcVH($text);
    line-height: calcVH(36);
    font-weight: 600;
    border-bottom: calcVH(1) solid #000000;
    padding-bottom: calcVH(10);
    caret-color: $cursor;
  }

  &_note {
    color: $primary;
    font-weight: 400;
    font-size: calcVH(14);
    line-height: calcVH(36);
    margin-top: calcVH(10);
  }

  &_users {
    margin-top: calcVH(24);
    height: calcVH(120);
    overflow: auto;
  }

  &_shadow {
    box-shadow: inset 0 -12px 11px -7px #E4ECEF;
  }
}

.error {
  border-bottom-color: $error;

  &_text {
    color: $error;
  }
}

input::placeholder {
  color: $primary;
}

.covid {
  &_text {
    font-size: calcVH($text-small);
    line-height: calcVH(24);
    font-weight: 600;
  }
}

.webcam {
  display: flex;
  align-items: center;
  justify-content: center;

  &_wrapper {
    width: calcVH(550);
    height: calcVH(550);
    border-radius: 50%;
    background-image: url('../../icons/border-inspace.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  &_video {
    width: calcVH(530);
    height: calcVH(530);
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      border-radius: 50%;
      height: 100%;
    }

    img {
      height: 100% !important;
    }
  }
}

.wifi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_icon {
    margin-bottom: calcVH(35);
  }

  &_list {
    width: 100%;
  }

  &_title {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    text-transform: capitalize;
    margin-bottom: calcVH(45);
  }

  &_list {
    display: flex;
    align-items: center;
    margin-bottom: calcVH(32);
  }

  &_label {
    min-width: calcVH(155);
    margin-right: calcVH(16);
    font-weight: 700;
    font-size: calcVH($text);
    line-height: calcVH(36);
  }

  &_value {
    font-weight: 700;
    font-size: calcVH($subtitle);
    line-height: calcVH(48);
    color: #3C7BFF;
  }

  &_password {
    letter-spacing: 7px;
  }
}

.select {
  position: relative;
  width: 100%;
  margin-bottom: calcVH(16);
  &_label {
    position: absolute;
    background: $color-light;
    left: calcVH(12);
    top: calcVH(-14);
    padding: calcVH(2) calcVH(5);
    font-size: calcVH(16);
    font-weight: 700;
  }
  &_field {
    height: calcVH(60);
    width: 100%;
    border-radius: calcVH(8);
    border: calcVH(2) solid $color-dark;
    background: $color-light;
    padding: calcVH(8) calcVH(16);
    appearance: none;
    background-repeat: no-repeat;
    background-position: calc(100% - 7px) 10px;
    background-size: 24px 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='33' viewBox='0 0 32 33' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M23.8024 10.501L26 12.6944L16.0087 22.7047L6 12.6954L8.19556 10.5L16.0067 18.3116L23.8024 10.501Z' fill='%23191919'/%3E%3C/svg%3E");

    options {
      font-size: calcVH(16);
      font-weight: 400;
    }
  }
}

.document_text {
  height: calcVH(500);
  overflow: auto;

  p {
    font-size: calcVH(18) !important;
    font-weight: 600 !important;
  }
}

.document_title {
  margin-bottom: calcVH(16);
}

select:required:invalid {
  color: #A1AEB7;
  font-size: 16px;
  font-weight: 400;
}
