@import "../../styles/const";

.welcome {
  color: $color-light;
  height: 100%;
  margin-top: calcVH(110);

  &_title {
    font-size: calcVH($title);
    line-height: calcVH($title);
    font-weight: 700;
    max-width: 700px;
  }

  &_buttons {
    margin-top: calcVH(110);
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons {
  &_item {
    color: $color-dark;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color-light;
    border-radius: calcVH(40);
    padding: calcVH(30) calcVH(44);
    font-size: calcVH($subtitle);
    line-height: calcVH(33);
    font-weight: 600;

    &:first-child {
      margin-bottom: calcVH(40);
    }

    svg {
      margin-left: calcVH(24);
    }
  }
}

.apps {
  margin-left: calcVH(40);
  border-radius: calcVH(72);
  background-color: $background-modal;
  padding: calcVH(20) calcVH(28);
  box-shadow: $box-shadow;

  @media screen and (orientation:landscape) {
    padding: calcVH(40) calcVH(48);
  }

  &_title {
    color: $color-dark;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: calcVH(36);
    line-height: calcVH(54);
    margin-bottom: calcVH(24);
  }
}

.qr {
  width: calcVH(166);
  margin-right: calcVH(24);
}

.icons {
  &_link {
    display: block;

    &:first-child {
      @media screen and (orientation:landscape) {
        margin-bottom: calcVH(24);
      }
    }
  }
  svg {
    height: calcVH(50);
    width: calcVH(175);
  }
}
