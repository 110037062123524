
@import "../../styles/const";

.signature {
  &_text {
    margin-bottom: calcVH(24);
  }
  &_field {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: calcVH(450);
    border-radius: calcVH(30);
    border: 2px solid $color-dark;
    background: $color-light;
  }
  &_btn-clear {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #3C7BFF;
  }

  &_canvas {
    touch-action: none;
  }
}