// font-size

$title: 92;
$subtitle: 32;
$text: 24;
$text-small: 18;

// color

$color-light: #fff;
$background-modal: #FDFDFD;
$color-dark: #000000;
$primary: #A1AEB7;
$cursor: #55DBFF;
$error: #FF0000FF;
$box-shadow: 2px 0px 23px rgba(57, 118, 255, 0.3);
$box-shadow-btn: 0px 4px 10px rgba(46, 82, 101, 0.15);

// size elem

$REFERENCE_HEIGHT: 1024;

@function calcVH($px) {
  @return ($px / $REFERENCE_HEIGHT * 100)  * 1vh;
}

// border

$border-radius-modal: 50;
$border-radius-btn: 30;
