@import "../../styles/const";

.delivery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &_text {
    font-weight: 700;
    font-size: calcVH($text);
    line-height: calcVH(36);
    margin: calcVH(40) 0;
  }

  &_buttons {
    margin-bottom: calcVH(115);
  }

  &_item {
    color: $color-light;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color-dark;
    border-radius: calcVH(40);
    padding: calcVH(30) calcVH(44);
    font-size: calcVH($text);
    line-height: calcVH(33);
    font-weight: 600;

    &:first-child {
      margin-bottom: calcVH(40);
    }

    svg {
      margin-left: calcVH(24);
    }
  }

  &_note {
    font-weight: 700;
    font-size: calcVH($text);
    line-height: calcVH(36);
    margin-bottom: calcVH(36);
  }
}
