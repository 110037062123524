@import "../../styles/const";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: calcVH(40);
}

.container {
  width: 100%;
  height: 100vh;
  color: $color-dark;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: flex-end;

  &_logo {
    max-height: calcVH(100);
    max-width: calcVH(200);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.content {
  height: 100%;
}

.fullscreen {
  margin-top: calcVH(20);
  color: $color-light;

  &_button {
    display: flex;
    align-items: center;
  }

  &_text {
    font-weight: 600;
    font-size: calcVH(16);
    line-height: calcVH(24);
    margin-right: calcVH(24);
  }

  &_img {
    width: calcVH(23);
    height: calcVH(23);
  }
}

.left-to-right {
  &-enter {
    opacity: 0;
    transform: translateX(-100%);
    height: 100%;
  }

  &-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 1s ease;
    height: 100%;
  }

  &-exit {
    opacity: 0;
    transform: translateX(0);
    height: 100%;
  }

  &-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: all 1s ease;
    height: 100%;
  }
}

.right-to-left {
  &-enter {
    opacity: 0;
    transform: translateX(100%);
    height: 100%;
  }

  &-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 1s ease;
    height: 100%;
  }

  &-exit {
    opacity: 0;
    transform: translateX(0);
    height: 100%;
  }

  &-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: all 1s ease;
    height: 100%;
  }
}
