@import "../../styles/const";

.user {
  display: flex;
  align-items: center;
  margin-bottom: calcVH(16);
  cursor: pointer;

  &_avatar {
    width: calcVH(46);
    height: calcVH(46);
    background: $color-dark;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_name {
    font-weight: 500;
    font-size: calcVH($text-small);
    line-height: 130%;
    margin-left: calcVH(16);
  }
}