@import "../../styles/const";

.thanks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_title {
    font-weight: 700;
    font-size: calcVH(64);
    line-height: calcVH(96);
    margin-bottom: calcVH(32);
  }

  &_text {
    text-align: center;
    font-size: calcVH($subtitle);
    line-height: calcVH(61);
    margin-bottom: calcVH(20);
  }

  &_error {
    &-title {
      text-align: center;
      font-weight: 700;
      font-size: calcVH(64);
      line-height: calcVH(96);
      color: $error;
    }

    &-text {
      text-align: center;
      font-size: calcVH($subtitle);
      line-height: calcVH(61);
      margin-bottom: calcVH(20);
    }

  }
}
