@import "../../styles/const";

.card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calcVH(900);
    min-height: calcVH(400);
    background: $background-modal;
    border-radius: calcVH($border-radius-modal);
    box-shadow: $box-shadow;
    padding: calcVH(30) calcVH(56);
    z-index: 99999;

    .item {
      &:first-child {
        width: 10%;
      }

      &:last-child {
        width: 90%;
      }
    }
  }

  &_title {
    text-align: center;
    font-weight: 700;
    font-size: calcVH($subtitle);
    line-height: calcVH(48);
    margin-right: calcVH(70);
  }

  &_content {
    flex: auto;
    margin-top: calcVH(24);
    position: relative;
    z-index: 1000;
  }

  &_button {
    display: block;
    background-color: $color-dark;
    width: 100%;
    box-shadow: $box-shadow-btn;
    border-radius: calcVH($border-radius-btn);
    padding: calcVH(16) calcVH(44);
    font-weight: 600;
    font-size: calcVH($text);
    line-height: calcVH(33);
    text-align: center;
    margin-top: calcVH(24);
    color: $color-light;

    .spinner {
      position: relative;
    }

    .spinner_elem {
      border-bottom-color: #fff;
    }
  }

  &_api-error {
    color: $error;
  }
}

.disabled {
  background: $primary;
  pointer-events: none;
}
